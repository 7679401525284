import React from 'react';
import './Modal.scss';

import { IoClose } from "react-icons/io5";

function Modal({ isOpen, setModalOpen, children }) {
    if (isOpen) {
        return (
            <div className='modal--ofertas'>
                <div className='overlay' onClick={setModalOpen}></div>
                <div className='modal--content'>
                    <div className='close--modal' onClick={setModalOpen}>
                        <IoClose />
                    </div>
                    <div style={{ height: '100%' }}>{children}</div>
                </div>
            </div>
        )
    }

    return null
}

export default Modal;
